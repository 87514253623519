import type { RouteRecordRaw } from 'vue-router';

import { USER_ROLE } from '@billtrackpro-wilsonelser/api/enums';
import enterDataview from '@/util/enterDataview.js';
import castRouteParams from '@/util/castRouteParams.js';

import { useAuthStore } from '@/store/auth.js';

const routes: RouteRecordRaw[] = [
	{
		path: '/admin',
		component: () => import('@/layouts/SiteContainer.vue'),
		children: [
			{
				path: "",
				redirect: to => {
					const authStore = useAuthStore();
					if ( !authStore.user ) {
						return { name: 'AppLogin', query: { redirect: '/admin' }}
					}

					if ( authStore.allow(USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER) ) {
						return { name: 'AdminInvoiceDataview' }
					}
					else if ( authStore.allow(USER_ROLE.ICJ) ) {
						return { name: "BTPRedirect", query: { to: "/admin/icj" }};
					}
					else {
						return { name: "NotAuthorized", query: { target: to.fullPath } };
					}
				}
			},
			{
				path: "invoices/submit",
				name: "AdminInvoiceSubmit",
				component: () => import('./invoices/submit/AdminInvoiceSubmit.vue'),
				meta: {
					title: "Submit New Invoice",
					allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER],
				},
			},
			{
				path: "invoices",
				redirect: { name: "AdminInvoiceDataview" },
				children: [
					{
						path: "",
						name: "AdminInvoiceDataview",
						component: () => import('./invoices/dataview/AdminInvoiceDataview.vue'),
						meta: {
							title: "Invoices",
							allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER],
						},
						beforeEnter: [ enterDataview ],
					},
					{
						path: ":invoiceID(\\d+)",
						name: "AdminInvoiceDetails",
						redirect: { name: "AdminInvoiceView" },
						component: () => import('./invoices/details/AdminInvoiceDetails.vue'),
						props: castRouteParams({ invoiceID: Number }),
						children: [
							{
								path: "",
								name: "AdminInvoiceView",
								components: {
									default: () => import('./invoices/details/view/AdminInvoiceView.vue'),
									history: () => import('./invoices/details/view/InvoiceHistory.vue'),
								},
								meta: {
									allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER],
								},
							},
							{
								path: "review",
								name: "AdminInvoiceReview",
								redirect: { name: "AdminInvoiceReviewHardcopy" },
								components: {
									default: () => import('./invoices/details/review/AdminInvoiceReview.vue'),
								},
								children: [
									{
										path: "hardcopy",
										name: "AdminInvoiceReviewHardcopy",
										component: () => import('./invoices/details/review/AdminInvoiceReviewHardcopy.vue'),
									},
									{
										path: "items",
										name: "AdminInvoiceReviewItems",
										component: () => import('./invoices/details/review/AdminInvoiceReviewItems.vue'),
									},
									{
										path: "confirm",
										name: "AdminInvoiceReviewConfirm",
										component: () => import('./invoices/details/review/AdminInvoiceReviewConfirm.vue'),
									},
								],
								meta: {
									allowedRoles: [USER_ROLE.ADMIN,USER_ROLE.ATTORNEY,USER_ROLE.DELEGATE,USER_ROLE.OFFICE_MANAGER],
								},
							},
							{
								path: "forward",
								name: "AdminInvoiceForward",
								redirect: { name: "AdminInvoiceForwardMethodContact" },
								components: {
									default: () => import('./invoices/details/forward/AdminInvoiceForward.vue'),
								},
								children: [
									{
										path: "contact",
										name: "AdminInvoiceForwardMethodContact",
										component: () => import('./invoices/details/forward/ForwardMethodContact.vue'),
									},
									{
										path: "message",
										name: "AdminInvoiceForwardMessage",
										component: () => import('./invoices/details/forward/ForwardMessage.vue'),
									},
									{
										path: "files",
										name: "AdminInvoiceForwardAttachments",
										component: () => import('./invoices/details/forward/ForwardAttachments.vue'),
									},
									{
										path: "confirm",
										name: "AdminInvoiceForwardConfirm",
										component: () => import('./invoices/details/forward/ForwardConfirm.vue'),
									},
									{
										path: "again",
										name: "AdminInvoiceForwardAgain",
										component: () => import('./invoices/details/forward/ForwardAgain.vue'),
									},
								],
								meta: {
									allowedRoles: [USER_ROLE.ADMIN,USER_ROLE.ATTORNEY,USER_ROLE.DELEGATE,USER_ROLE.OFFICE_MANAGER],
								},
							},
						]
					},
				]
			},
			{
				path: "invoices/drafts",
				name: "AdminInvoiceDrafts",
				component: () => import('./invoices/drafts/AdminInvoiceDrafts.vue'),
				meta: {
					title: "Drafts",
				},
				beforeEnter: [ enterDataview ],
			},
			{
				path: "action-items",
				name: "AdminActionItems",
				component: () => import('./action/ActionItems.vue'),
				meta: {
					title: "Action Items",
					allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER],
				},
				children: [
					{
						path: "missing-reviewers",
						name: "AdminMissingReviewers",
						component: () => import('./action/missing/MissingReviewers.vue'),
						meta: {
							title: "Invoices Missing Reviewers",
							allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER],
						},
					},
					{
						path: "missing-forwarders",
						name: "AdminMissingForwarders",
						component: () => import('./action/missing/MissingForwarders.vue'),
						meta: {
							title: "Invoices Missing Forwarders",
							allowedRoles: [USER_ROLE.ADMIN],
						},
					},
					{
						path: "deliver-invoices",
						name: "AdminInvoiceDelivery",
						component: () => import('./action/deliver/AdminInvoiceDelivery.vue'),
						meta: {
							title: "Pending Client Delivery",
							allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER],
						},
					},
				]
			},
			{
				path: "invoices/missing/reviewers",
				redirect: { name: 'AdminMissingReviewers' },
			},
			{
				path: "invoices/missing/forwarders",
				redirect: { name: 'AdminMissingForwarders' },
			},
			{
				path: "invoices/deliver",
				redirect: { name: 'AdminInvoiceDelivery' },
			},
			{
				path: "vendors",
				redirect: { name: "AdminVendors" },
				children: [
					{
						path: "",
						name: "AdminVendors",
						component: () => import('./vendors/AdminVendors.vue'),
						meta: {
							title: "Vendors",
							allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER],
						},
						beforeEnter: [ enterDataview ],
					},
					{
						path: "accounts/:vendorID",
						name: "AdminVendorAccount",
						component: () => import('./vendors/AdminVendorAccountDetails.vue'),
						props: castRouteParams({ vendorID: Number }),
						meta: {
							allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER],
						}
					},
					{
						path: "registrations/:registrationID",
						name: "AdminVendorRegistration",
						component: () => import('./vendors/AdminVendorRegistrationDetails.vue'),
						props: castRouteParams({ registrationID: Number }),
						meta: {
							allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER],
						}
					},
				]
			},
			{
				path: "users",
				redirect: { name: "AdminStaffUsers" },
				children: [
					{
						path: "vendors",
						children: [
							{
								path: "",
								name: "AdminVendorUsers",
								component: () => import('./users/AdminUsers.vue'),
								meta: {
									title: "Vendor Users",
									userGroup: "vendors",
									allowedRoles: [USER_ROLE.ADMIN],
								},
								beforeEnter: [ enterDataview ],
							},
							{
								path: ":userID(\\d+)",
								name: "AdminVendorUserDetails",
								component: () => import('./users/AdminUserDetails.vue'),
								meta: {
									userGroup: "vendors",
									allowedRoles: [USER_ROLE.ADMIN],
								}
							},
						],
					},
					{
						path: "staff",
						children: [
							{
								path: "",
								name: "AdminStaffUsers",
								component: () => import('./users/AdminUsers.vue'),
								meta: {
									title: "Staff Users",
									userGroup: "staff",
									allowedRoles: [USER_ROLE.ADMIN,USER_ROLE.OFFICE_MANAGER],
								},
								beforeEnter: [ enterDataview ],
							},
							{
								path: ":userID(\\d+)",
								name: "AdminStaffUserDetails",
								component: () => import('./users/AdminUserDetails.vue'),
								meta: {
									userGroup: "staff",
									allowedRoles: [USER_ROLE.ADMIN,USER_ROLE.OFFICE_MANAGER],
								}
							},
						],
					},
				]
			},
			{
				path: "offices",
				redirect: { name: "AdminOffices" },
				children: [
					{
						path: "",
						name: "AdminOffices",
						component: () => import('./offices/AdminOffices.vue'),
						meta: {
							title: "Offices",
							allowedRoles: [USER_ROLE.ADMIN,USER_ROLE.OFFICE_MANAGER],
						},
					},
					{
						path: ":officeCode",
						name: "AdminOfficeDetails",
						component: () => import('./offices/AdminOfficeDetails.vue'),
						props: true,
						meta: {
							allowedRoles: [USER_ROLE.ADMIN,USER_ROLE.OFFICE_MANAGER],
						}
					},
				]
			},
			{
				path: "service-codes",
				name: "AdminServiceCodes",
				component: () => import('./service-codes/AdminServiceCodes.vue'),
				meta: {
					title: "Service Codes",
					allowedRoles: [USER_ROLE.ADMIN],
				},
			},
			{
				path: "rules",
				redirect: { name: "AdminClientRules" },
				children: [
					{
						path: "",
						name: "AdminClientRules",
						component: () => import('./rules/AdminClientRules.vue'),
						meta: {
							title: "Client Rules",
							allowedRoles: [USER_ROLE.ADMIN],
						},
					},
				]
			},
			{
				path: "email-history",
				name: "AdminEmailHistory",
				component: () => import('./email-history/AdminEmailHistoryDataview.vue'),
				meta: {
					title: "Email History",
					allowedRoles: [USER_ROLE.ADMIN],
				},
			},
			{
				path: "lookup",
				name: "AdminMatterLookup",
				component: () => import('./lookup/AdminMatterLookup.vue'),
				meta: {
					title: "Matter Lookup",
				},
			},
			{
				path: "account",
				name: "AdminAccountSettings",
				component: () => import('./account/AccountSettings.vue'),
				meta: {
					title: "Account Settings",
				},
			},
			{
				path: "support",
				name: "AdminSupport",
				component: () => import('./AdminSupport.vue'),
				meta: {
					title: "Help & Support",
				},
			},
		],
	},
];

export default routes;